* {
  margin: 0;
}

body{
  overflow-y:hidden;
  overflow-x:hidden;
}

@font-face{
  font-family: russoOne;
  src: url('./assets/fonts/RussoOne-Regular.ttf');
}

@font-face{
  font-family: telegramaPixel;
  src: url('./assets/fonts/telegrama_raw.otf');
}

@font-face{
  font-family: safety;
  src: url('./assets/fonts/SafetyMedium.otf');
}

@font-face{
  font-family: mechEffects;
  src: url('./assets/fonts/mecheffects2-bb.regular.ttf');
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Tablet stuff */

#tablet{
  height:80vh;
  width:140vh;
  border-top: 1.5vh solid #000;
  border-bottom: 1.5vh solid #000;
  border-radius:5vh;
  background:black;
}

#tabletWrapper{
  height:inherit;
  width:inherit;
  margin:0;
  display:flex;
  position:absolute;
}

#tabletCamera{
  width:5%;
  height:calc(2vh + 100%);
  transform: translateY(-1vh);
  margin:0;
  display:inline-block;
  background:black;
  border-radius:5vh 0 0 5vh;
  display:flex;
  align-items:center;
  justify-content:center;
}

#tabletLens{
  height:0;
  width:20%;
  padding-bottom: 20%;
  background-color:#afeeee44;
  border-radius:50%;
}

#tabletHome{
  width:5%;
  height:calc(2vh + 100%);
  transform: translateY(-1vh);
  margin:0;
  background:black;
  border-radius: 0 5vh 5vh 0;
  display:flex;
  align-items:center;
  justify-content:center;
  position:relative;
}

#homeButton{
  height:0;
  width: 80%;
  padding:0;
  padding-bottom:calc(80% - 2 * 0.4vh);
  box-sizing: border-box;
  background-color:#afeeee66;
  border: 0.4vh rgb(24, 24, 24) solid;
  border-radius:50%;
  transition: background-color 0.2s, box-shadow 0.5s;
  z-index:201;
}

#goHome:hover{
  background-color:#000;
}

#goHome{
  width: 6%;
  padding:0;
  right: 1%;
  top:1%;
  box-sizing: border-box;
  background-color:#111;
  transition: background-color 0.2s, box-shadow 0.5s;
  position:absolute;
  z-index:201;
  display:flex;
  align-items:center;
  justify-content:center;
  border:none;
}

#goHome > img{
  width:70%;
  height:70%;
  padding-top:15%;
  padding-bottom:15%;
}

#buttonRadius{
  width:20%;
  height:0;
  padding-bottom:20%;
  top:35%;
  left:90%;
  position:absolute;
  border-radius:50%;
  z-index:10;
}

#homeButton:active{
  background-color:#afeeee44;
}

#tabletScreen{
  width:90%;
  height:100%;
  margin:0;
  display:inline-block;
  overflow: hidden;
  position:relative;
}

#activeApp{
  position:absolute;
  z-index:100;
  height:100%;
  width:100%;
  visibility:hidden;
}

/* Specifically for broken tablet mode */

/*#activeApp{
  
}*/

#crackedGlass{
  z-index:101;
  position:absolute;
  height:100%;
  width:100%;
  object-fit: contain;
}

#crackedGlass > img{
  z-index:101;
  width:100%;
  height:100%;
}


#activeApp.loading{
  animation: fadeInAnimation ease 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

#activeApp.return{
  animation: fadeOutAnimation ease 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 1;
}

#activeApp.inUse{
  visibility:visible;
}

#notificationTray{
  width:90%;
  height:5%;
  left:5%;
  background:#444B;
  position:absolute;
  display:flex;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: flex-start;
  flex-direction: row;
  align-items: center;
}

#clock{
  font-size:2.5vh;
  padding-left:1%;
}
#icons{
  height:100%;
  width:20%;
  display:flex;
  flex-direction:row-reverse;
  align-items:center;
  padding-right:2%;
}
#icons > img {
  padding-left:10%;
  height:60%;
}
/* Landing */

.landingOverlay{
  display:flex;
  align-items:center;
  justify-content:center;
  height:100%;
  width:100%;
  position:absolute;
  z-index:0;
}

.landingBackground{
  position:absolute;
  filter: brightness(0.8);
  background: slategray;
  background-image: url('/public/landingbg.jpg');
  box-sizing: border-box;
  background-size: cover;
  height:100%;
  width:100%;
  top:0;
  left:0;
  z-index:-2;
}

.landingMenu{
  height:inherit;
  width:90%;
  margin:auto;
  align-items:center;
  display:flex;
  z-index:1;
}

.landingGrid {
  display: grid;
  grid-template-areas:
  'rules contacts map stats settings'
  'chatroom notepad camera recorder dossiers'
  '. . . . .';
  grid-auto-rows: minmax(0, 1fr);
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: 5%;
  padding:0;
  margin-top:6%;
  margin-bottom:4%;
  height:90%;
  width: 100%;
  align-items:center;
}


.imageContainer{
  position:relative;
}

.appButton{
  max-width:100%;
  background:none;
  border:none;
}


.appButton > p {
  position:relative;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color:white;
  font-size: 3vh;
  text-shadow: 0px 0px 10px #000, 0px 0px 10px #000, 0px 0px 10px #000, 0px 0px 10px #000, 0px 0px 5px #000, 0px 0px 2px #000;
}
.appButton:hover > div {
  filter:brightness(0.8);
}

.appButton.true .appIcon{
  filter:brightness(0.4);
}

.appButton:hover .appIcon{
  background:#0009;
  border-radius:5%;
}
.appButton:hover > p{
  filter:brightness(0.8);
  border-radius:5%;
}
.appIcon{
  max-width:80%;
}
.appButton.false .lock{
  display:none;
}

.lock{
  width:70%;
  position:absolute;
  left:15%;
  top:5%;
}


.Rules{
  grid-area:rules;
}
.Contacts{
  grid-area:contacts;
}
.Map{
  grid-area:map;
}
.Stats{
  grid-area:stats;
}
.Chatroom{
  grid-area:chatroom;
}
.Settings{
  grid-area:settings;
}
.Notepad{
  grid-area:notepad;
}
.Camera{
  grid-area:camera;
}
.Recorder{
  grid-area:recorder;
}
.Dossiers{
  grid-area:dossiers;
}
a{
  color:cyan;
}
a:visited{
  color:cyan;
}

/* ID Card */
#idcard {
  animation: fadeInAnimation ease 1s;
  animation-delay:0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

@-webkit-keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@-webkit-keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#idcard{
  margin:auto;
  width:105vh;
  height:70vh;
  perspective:1000px;
  border:transparent solid 1vh;
  z-index:2;
}

#idflip {
  border:transparent solid 10px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  margin:auto;
  min-width:105vh;
  min-height:70vh;
  top:-10px;
  left:-10px;
}

.flipped #idflip {
  transform: rotateY(-180deg);
}

.flipped #idfront {
  animation: hide 0.8s;
  animation-fill-mode:forwards;
}

.flipped #flipButtonFront{
  visibility:hidden;
  display:none;
}
@-webkit-keyframes hide {
  from {}
  to {visibility:hidden;}
}

@keyframes hide {
  from {}
  to {visibility:hidden;}
}

#idfront, #idback{
  position:absolute;
  min-width:105vh;
  min-height:70vh;
  width:100%;
  height:100%;
  top:-1vh;
  left:-1vh;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  background:pink;
  background-image: url('/public/bgtest.jpg');
}


.grid-container > div p span{
  color:white;
}

.idgeo p, .idbio1 p, .idbio2 p {
  color:rgb(170, 170, 170);
}

#idfront{
  border:#ff2272 solid 1vh;
  background-size:cover;
}

#idcard{
  font-family: safety;
}

#idheader, .idlevel{
  font-family: russoOne;
  color:#c2ff03;
}

#idheader{
  position:absolute;
  display:flex;
  align-items:center;
  vertical-align: middle;
  margin:0.5%;
}

#idheader > h1{
  text-align:left;
  display:inline;
  margin-left:10px;
  font-size:6vh;
}

#idlogo{
  height:8vh;
}

#iddata{
  display:flex;
  width:100%;
  height:68vh;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom:2vh;
}

/* TODO: Nix this when on mobile */
#idportrait{
  margin-left:2%;
  max-height:85%;
  border: #c2ff03 solid 2px;
  border-radius:5%;
  background:#0004;
}

#idcard  #iddata > .isaakWrapper {
  margin-left:2%;
  max-height:85%;
  max-width: 36%;
  border: #c2ff03 solid 2px;
  border-radius:5%;
  background:#0004;
}

#idcard  #idportrait.wolfe, #idcard #idportrait.isaak{
  margin: 0;
  padding: 0;
  max-height: 100%;
  border: none;
  border-radius: 0;
  background: none;
}

.grid-container {
  display: grid;
  grid-template-areas:
    'title title title title title title stats stats'
    'level level level level level level phys phys'
    'geo geo geo geo geo geo mental mental'
    'bio1 bio1 bio1 bio2 bio2 bio2 murder murder'
    'likes likes likes likes dislikes dislikes dislikes dislikes';
  grid-template-rows: 1fr 1.5fr 1.5fr 1.5fr 2fr;
  gap: 1vh;
  height:85%;
  width:100%;
  padding:0;
  margin:0;
  margin-left:10px;
  margin-right:10px;
  align-items:stretch;
}

.isaak .grid-container{
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}


.grid-container > div {
  background: #0003;
  background-size: 100%;
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}

.grid-container > .idlikes, .grid-container > .iddislikes {
  background-size: 100%;
  display:block;
}

.flexbreak{
  flex-basis:100%;
}

.grid-container > div > p{
  background-size: 100%;
  vertical-align:center;
  align-items:center;
}

.idtitle{
  grid-area: title;
  text-align: center;
  font-size:2.5vh;
  font-variant: small-caps;
}

.statsheader{
  font-variant: small-caps;
  grid-area: stats;
  text-align:center;
  font-weight:bold;
  font-size:4vh;
}

.stats{
  font-size:2vh;
}

.idlevel{
  grid-area: level;
  font-size:6vh;
  text-align:center;
}

.phys{
  grid-area: phys;
  text-align:center;
  color:#ff4885
}

.idgeo{
  grid-area: geo;
  text-align:center;
  font-size:2.5vh;
}

.mental{
  grid-area: mental;
  text-align:center;
  color: #ffc9d2;
}

.idbio1{
  grid-area: bio1;
  text-align:center;
  font-size:2.5vh;
}

.idbio2{
  grid-area: bio2;
  text-align:center;
  font-size:2.5vh;
}

.murder{
  grid-area: murder;
  text-align:center;
  color: #d1ec6e;
}

.idlikes, .iddislikes{
  padding-top:1vh;
  padding-bottom:1vh;
}

.idlikes{
  grid-area: likes;
  font-size:3vh;
  text-align:center;
  display:flex;
}

.iddislikes{
  text-align:center;
  grid-area: dislikes;
  font-size:3vh;
  display:flex;
}

/* TODO: Fucking fix the alignment here */

.idlikes  h3, .iddislikes  h3{
  position:relative;
  font-variant: small-caps;
}

.idlikes{
  color:#c2ff03;
}

.iddislikes{
  color:#ff2272;
}

.idlikes p, .iddislikes p{
  position:relative;
  font-size:2.5vh;
  text-align:center;
  padding: 0 1vh;
  padding-bottom:1vh;
  filter:saturate(0.2);
}

.iddislikes p{
  filter:brightness(5);
}

#idback{
  transform: rotateY(-180deg);
  border:#ff2272 solid 1vh;
  background-size:cover;
}

#idback > div{
  display:flex;
  align-items:stretch;
  flex-direction:column;
  margin:2%;
  margin-top:0;
  text-align:left;
}

#bioheader{
  position:relative;
  margin: 3vh 0;
  padding: 1vh 0;
  padding-top: 2vh;
  font-variant: small-caps;
  font-size:3vh;
  color:#c2ff03;
  /*background:#0005;*/
}

#bioheader:before{
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: opacity(0.6);
  background-image:url('/public/bio_header.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

#bioheader > h1{
  position:relative;
}

#bio{
  padding: 0 1vh;
  font-size:3vh;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.grid-container  div  p  span{
  font-variant: small-caps;
  font-size:3vh;
}

#idback > * > * > h1{
  text-align:center;
}

#flipButtonFront, #flipButtonBack{
  background:transparent;
  border:none;
  position:absolute;
  top:95%;
  left:101%;
  filter:brightness(0.5);
}

#closeButtonFront, #closeButtonBack{
  background:#0007;
  border:none;
  position:absolute;
  top:1%;
  right:1%;
  height:4vh;
  width:4vh;
  display:flex;
  align-items: center;
  justify-content: center;
}

.flipButton{
  visibility:hidden;
  animation-name:appearify;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: 1.5s;
}

@-webkit-keyframes appearify{
  from{}
  to{
    visibility:visible;
    display:block;
  }
}
@keyframes appearify{
  from{}
  to{
    visibility:visible;
    display:block;
  }
}

#flipButtonFront:hover, #flipButtonBack:hover{
  filter:none;
}

#closeButtonFront:hover, #closeButtonBack:hover{
  background:#000;
}

#flipButtonFront > img, #flipButtonBack > img  {
  height:4vh;
}

#closeButtonFront > img, #closeButtonBack > img  {
  height:2vh;
}


/* Special cases */

.mai #idheader > h1{
  font-size:5vh;
}

.august #bio{
  font-size:2.8vh;
}

/* Cast Page */

#castOverlay {
  display:flex;
  align-items:center;
  justify-content:center;
  height:100%;
  background:rgb(139, 28, 46);
  background-image:url('/public/castbg.jpg');
  background-size:cover;
  position:relative;
  /*-webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);*/
} /* World's worst position:fixed hack */
/* This is also terrible. I need to fix the stupid fucking border hack I'm using later */

#castOverlay > div{
  position:absolute;
}

#castContainer{
  display:flex;
  align-items:center;
  z-index:0;
}

#castCover{
  visibility:hidden;
  position:fixed;
  transform:translateY(0) translateX(0);
  height:100vh;
  width:100vw;
  z-index:1;
  background-color: #0000;
  transition: background-color 0.5s;
}

#castCover.active{
  visibility:visible;
  background-color:#0003;
}

.square{
  position:relative;
  transition: height 1s, width 1s, top 1s, left 1s, filter 0.25s, visibility 1.5s, background-color 1s;
  height:18vh;
  width:18vh;
  top:0;
  left:0;
  align-items:center;
  text-align:center;
  background-color:rgba(calc(100 * 0.24), calc(100 * .08), calc(100 * .16), 0.6)

}

.square.loadOut{
  z-index:1;
}

.square.clicked{
  z-index:2;
  background-color:#b83967;
  visibility: hidden;
  transition: height 0.5s, width 0.5s, top 0.5s, left 0.5s, filter 0.5s, visibility 1.5s, background-color 0.5s;
}

/* Proper calculation for card relative to the grid

top: calc(-35vh - 10px);
left: calc(-52.5vh - 10px);*/

.rei.clicked{ 
  top: 1.5vh;
  left: -6.5vh;
  width:107vh;
  height:72vh;
}
.andrew.clicked{
  top: 1.5vh;
  left: -25.5vh;
  width:107vh;
  height:72vh;
}
.avery.clicked{
  top: 1.5vh;
  left: -44.5vh;
  width:107vh;
  height:72vh;
}
.camille.clicked{
  top: 1.5vh;
  left: -63.5vh;
  width:107vh;
  height:72vh;
}
.ami.clicked{
  top: 1.5vh;
  left: -82.5vh;
  width:107vh;
  height:72vh;
}
.emma.clicked{ 
  top: -17.5vh;
  left: -6.5vh;
  width:107vh;
  height:72vh;
}
.empty.clicked{
  top: -17.5vh;
  left: -25.5vh;
  width:107vh;
  height:72vh;
}
.august.clicked{
  top: -17.5vh;
  left: -44.5vh;
  width:107vh;
  height:72vh;
}
.owen.clicked{
  top: -17.5vh;
  left: -63.5vh;
  width:107vh;
  height:72vh;
}
.yiseul.clicked{
  top: -17.5vh;
  left: -82.5vh;
  width:107vh;
  height:72vh;
}

.isaak.clicked{ 
  top: -36.5vh;
  left: -6.5vh;
  width:107vh;
  height:72vh;
}
.jones.clicked{
  top: -36.5vh;
  left: -25.5vh;
  width:107vh;
  height:72vh;
}
.hatman.clicked{
  top: -36.5vh;
  left: -44.5vh;
  width:107vh;
  height:72vh;
}
.v.clicked{
  top: -36.5vh;
  left: -63.5vh;
  width:107vh;
  height:72vh;
}
.mai.clicked{
  top: -36.5vh;
  left: -82.5vh;
  width:107vh;
  height:72vh;
}

.yumeji.clicked{
  top: -55.5vh;
  left: -6.5vh;
  width:107vh;
  height:72vh;
}
.rigel.clicked{
  top: -55.5vh;
  left: -25.5vh;
  width:107vh;
  height:72vh;
}
.gaz.clicked{
  top: -55.5vh;
  left: -44.5vh;
  width:107vh;
  height:72vh;
}
.simon.clicked{
  top: -55.5vh;
  left: -63.5vh;
  width:107vh;
  height:72vh;
}
.matsuno.clicked{
  top: -55.5vh;
  left: -82.5vh;
  width:107vh;
  height:72vh;
}
.allison.clicked{
  top: -55.5vh;
  left: -82.5vh;
  width:107vh;
  height:72vh;
}

.cast-grid {
  display: grid;
  grid-template-areas:
    'rei andrew avery camille ami'
    'emma empty august owen yiseul'
    'isaak jones hatman v mai'
    'yumeji rigel gaz simon allison';
  grid-template-rows: 18vh 18vh 18vh 18vh;
  grid-template-columns: 18vh 18vh 18vh 18vh 18vh;
  gap: 1vh;
  height:fit-content;
  width:fit-content;
  padding:0;
  margin:0;
  background: rgba(calc(100 * 0.72), calc(100 * .24), calc(100 * .49), 0.5);
}

.studentMug, .chapMug{
  max-width:95%;
  max-height:95%;
}

.isaakWrapper{
  height: 100%;
  width: 100%;
  position:relative;
}

.isaakWrapper > img {
  position: relative;
  opacity: 0;
}

.quantumOne{
  visibility: hidden;
  opacity: 0;
  transform: translateY(-100%);
  animation: xfade 5s 0s infinite;
  -moz-animation: xfade 5s 0s infinite;/* Firefox */
  -webkit-animation: xfade 5s 0s infinite; /* Safari and Chrome */
  -o-animation: xfade 5s 0s infinite;
}

.quantumTwo{
  visibility: hidden;
  opacity: 0;
  animation: xfade 5s 2.5s infinite;
  -moz-animation: xfade 5s 2.5s infinite;/* Firefox */
  -webkit-animation: xfade 5s 2.5s infinite; /* Safari and Chrome */
  -o-animation: xfade 5s 2.5s infinite;
}

#idcard .isaakWrapper{
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#idcard .idlikes > .isaakWrapper, #idcard .iddislikes > .isaakWrapper {
  top: 5vh;
  height: auto;
  width: auto;
}

#bio .quantumOne, #bio .quantumTwo{
  top: 0;
}

#idcard .quantumOne{
  transform: none;
  position: absolute;
}

#idcard .quantumTwo{
  position: absolute;
}

@-webkit-keyframes xfade{ /* Safari and Chrome */
  0%{
    opacity: 0;
  }
  10%{
    opacity: 1;
  }
  52% {
    opacity: 1;
  }
  60%{
    opacity: 0;
  }
}

 @-moz-keyframes xfade { 
  0%{
    opacity: 0;
  }
  10%{
    opacity: 1;
  }
  52% {
    opacity: 1;
  }
  60%{
    opacity: 0;
  }
}

 @-o-keyframes xfade { 
  0%{
    opacity: 0;
  }
  10%{
    opacity: 1;
  }
  52% {
    opacity: 1;
  }
  60%{
    opacity: 0;
  }
}

 @-ms-keyframes xfade { 
  0%{
    opacity: 0;
  }
  10%{
    opacity: 1;
  }
  52% {
    opacity: 1;
  }
  60%{
    opacity: 0;
  }
}

@keyframes xfade{
  0%{
    visibility: visible;
    opacity: 0;
  }
  10%{
    opacity: 1;
  }
  52% {
    opacity: 1;
  }
  60%{
    opacity: 0;
    visibility: hidden;
  }
}

.student > button, .chap > button{
  width:100%;
  height:100%;
  background:none;
  border:none;
  padding:0;
}

.student:hover, .chap:hover{
  filter:brightness(0.6);
  background:#000a;
}

.rei{
  grid-area: rei;
}
.andrew {
  grid-area: andrew;
}
.avery {
  grid-area: avery;
}
.camille {
  grid-area: camille;
}
.ami {
  grid-area: ami;
}
.emma {
  grid-area: emma;
}
.empty {
  grid-area: empty;
}
.august {
  grid-area: august;
}
.owen {
  grid-area: owen;
}
.yiseul {
  grid-area: yiseul;
}
.isaak {
  grid-area: isaak;
}
.jones {
  grid-area: jones;
}
.hatman {
  grid-area: hatman;
}
.v {
  grid-area: v;
}
.mai {
  grid-area: mai;
}
.yumeji {
  grid-area: yumeji;
}
.gaz {
  grid-area: gaz;
}
.simon {
  grid-area: simon;
}
.matsuno {
  grid-area: matsuno;
}
.rigel {
  grid-area: rigel;
}
.allison {
  grid-area: allison;
}

.simon, .andrew, .yiseul, .mai, .avery, .rei, .v, .camille, .empty, .ami, .owen, .gaz, .rigel, .emma, .allison {
  filter: grayscale(0.9);
}
.chapMug.simon, .cast-grid .studentMug.andrew, .cast-grid .studentMug.yiseul, .cast-grid .studentMug.mai, .cast-grid .studentMug.avery, .cast-grid .studentMug.rei, .cast-grid .studentMug.v, .cast-grid .studentMug.camille, .cast-grid .studentMug.empty, .cast-grid .studentMug.ami, .cast-grid .studentMug.owen, .chapMug.gaz, .cast-grid .studentMug.rigel, .cast-grid .studentMug.emma, .chapMug.allison {
  filter: grayscale(0.9) blur(0.3vh);
}

/* Map bullshit */

#mapOverlay{
  display:flex;
  align-items:center;
  justify-content:center;
  position:absolute;
  z-index:0;
  height: 100%;
  width: 100%;
}

#mapContainer, .mapMap{
  position:relative;
  display:flex;
  align-items:center;
  height:100%;
  width: 100%;
  background: #150700;
}

#img-mapper{
  margin:auto;
  display:flex;
  align-items:center;
  height:100%;
}

#img-mapper > img{
  position:unset;
  height:100%;
}

/* Template stuff */

#templateOverlay{
  display:flex;
  align-items:center;
  justify-content:center;
  height:100%;
  width:100%;
  position:absolute;
  z-index:0;
  background:#0008
}

#templateContainer{
  width:80%;
  text-align:center;
  background:#333;
  padding:5%;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size:4vh;
}










/* Rules Page & Card */

#rulesOverlay {
  display:flex;
  align-items:center;
  justify-content:center;
  height:100%;
  background: maroon;
  background-size:cover;
  position:relative;
}

.rulecard {
  position:absolute;
  animation: fadeInAnimation ease 1s;
  animation-delay:0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
  transform-style: preserve-3d;
}

.cardLeft {
  clip-path: polygon(
                    -5% 14.63%, 105% 14.63%, 
                    105% 15.36%, -5% 15.36%, 
                    -5% 27.20%, 105% 27.20%,
                    105% 41.78%, -5% 41.78%, 
                    -5% 72.81%, 105% 72.81%,
                    105% 81.70%, -5% 81.70%, 
                    -5% 82.21%, 105% 82.21%,
                    105% 88.55%, -5% 88.55%, 
                    -5% 96.29%, 105% 96.29%,
                    105% 105%, -5% 105%
                    );
                    transform-style: preserve-3d;
}
.cardRight {
  clip-path: polygon(-5% -5%, 105% -5%, 
                    105% 14.63%, -5% 14.63%, 
                    -5% 15.36%, 105% 15.36%, 
                    105% 27.20%, -5% 27.20%,
                    -5% 41.78%, 105% 41.78%, 
                    105% 72.81%, -5% 72.81%,
                    -5% 81.70%, 105% 81.70%, 
                    105% 82.21%, -5% 82.21%,
                    -5% 88.55%, 105% 88.55%, 
                    105% 96.29%, -5% 96.29%
                    );
                    transform-style: preserve-3d;
}

.cardLeft:not(.flipped), .cardRight.flipped {
  transform: translateX(-10px);
  transition: transform 0.8s ease-out;
}
.cardLeft.flipped, .cardRight:not(.flipped) {
  transform: translateX(10px);
  transition: transform 0.8s ease-out;
}

.rulecard{
  margin:auto;
  width:105vh;
  height:70vh;
  border:transparent solid 1vh;
  z-index:2;
  transform-style: preserve-3d;
}

#ruleflip {
  border:transparent solid 10px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  margin:auto;
  min-width:105vh;
  min-height:70vh;
  top:-10px;
  left:-10px;
}

.flipped #ruleflip {
  transform: rotateY(-180deg);
}

.flipped #rulefront {
  animation: hide 0.8s;
  animation-fill-mode:forwards;
}

.flipped #flipButtonFront{
  visibility:hidden;
  display:none;
}

#rulefront, #ruleback{
  position:absolute;
  min-width:105vh;
  min-height:70vh;
  width:100%;
  height:100%;
  top:-1vh;
  left:-1vh;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  background: black;
}


#rulefront{
  border:#c2ff03 solid 1vh;
  background-size:cover;
}

#rulecard{
  font-family: safety;
}

#ruleheader{
  font-family: russoOne;
  color:#ff2272;
  justify-content:center;
  display:flex;
  align-items:center;
  text-align:center;
  vertical-align: middle;
  margin:0.5%;
}

#ruleheader > h1{
  text-align:center;
  display:inline;
  font-size:6vh;
}

#ruleback{
  transform: rotateY(-180deg);
  border:#c2ff03 solid 1vh;
  background-size:cover;
}


/*#ruleheader:before{
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: opacity(0.6);
  background-image:url('/public/bio_header.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}*/

#ruleheader > h1{
  position:relative;
}

#ruleback > * > * > h1{
  text-align:center;
}

.rules, .mechs{
  text-align:left;
  overflow-y:scroll;
  height:61.5vh;
  line-height: 2em;
  font-size:3vh; 
  margin-left:1vh;
}

#houseRules, #killingRules, #mechanics, #commitRules{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 5vh;
  padding-inline-end:5vh;
}

#ruleImportant{
  color:maroon;
}


/* New shit for Trial 3 */

.glitch {
  position: relative;
  display: inline-block;
}
.glitch::before {
  left: -2px;
  text-shadow: -2px 0 #388;
  color: rgb(20, 156, 49);
  background: black;
  animation: glitch-anim-1 1.3s infinite linear alternate-reverse;
  text-overflow: clip;
}
.glitch::before, .glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.glitch::after {
  left: 1px;
  text-shadow: 1px 0 #484;
  color: rgb(19, 183, 189);
  background: black;
  animation: glitch-anim-1 1.4s infinite linear alternate-reverse;
  text-overflow: clip;
}
@keyframes glitch-anim-1 {
  0% {
      clip-path: inset(40% 0 61% 0);
  }
  20% {
      clip-path: inset(92% 0 1% 0);
  }
  40% {
      clip-path: inset(43% 0 22% 0);
  }
  60% {
      clip-path: inset(25% 0 72% 0);
  }
  80% {
      clip-path: inset(54% 0 31% 0);
  }
  100% {
      clip-path: inset(58% 0 43% 0);
  }
}

/* Stats page? */


#statsOverlay {
  display:flex;
  align-items:center;
  justify-content:center;
  height:100%;
  background: #2e1081;
  background-size:cover;
  position:relative;
}

#statsGrid {
  display: grid;
  grid-template-areas:
    'rei andrew'
    'avery camille'
    'ami emma'
    'empty august'
    'owen yiseul'
    'isaak jones'
    'hatman v'
    'mai yumeji'
    'rigel allison';
  grid-auto-rows: minmax(0, 1fr);
  grid-auto-columns: minmax(0, 1fr);
  gap: 1vh;
  height: 95%;
  width: 85%;
  padding:0;
  margin:0;
  background:0;
}

#statsOverlay > div{
  position:absolute;
}

.statContainer{
  height: 100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  z-index:0;
}

.statContainer .statStudentMug{
  background:#00000088;
  height: 100%;
  max-width: 15%;
  border: 2px solid #6b1ce0;
  box-sizing: border-box;
}

.statContainer.committed .statStudentMug {
  background:#77000088;
  height:100%;
  border: 2px solid #e01c8e;
  box-sizing: border-box;
}

.statContainer.committed .isaakWrapper{
  height: 200%;
  display: flex;
  flex-direction: column;
}

.statStudentMug .studentMug{
  height: 100%;
  max-height:100%;
  max-width:100%;
}

/*.statStudentMug .studentMug.wolfe, .statStudentMug .studentMug.isaak{
  height: 50%;
  width: 100%;
}*/

button.statContainer {
  background:0;
  border:0;
  transition: filter 0.5s;
}

button.statContainer:hover {
  filter: brightness(0.7);
  transition: filter 0.5s;
}

.charLevel{
  position:relative;
  height:100%;
  margin-left:1vh;
  flex-grow:1;
  background:#00000088;
  border: 2px solid #6b1ce0;
  box-sizing: border-box;
}

.committed .charLevel{
  background:#77000088;
  border: 2px solid #e01c8e;
}

.levelBar{
  height:100%;
  position:absolute;
  background:#6b1ce0;
}

.committed .levelBar{
  background: #e01c8e;
}

.levelText {
  font-size:4vh;
  font-family:russoOne;
  color:#c2ff03;
  width:fit-content;
  position:absolute;
  top:50%;
  transform: translateY(-50%) translateX(-50%);
  left:50%;
}

.committed .levelText {
  color:#ffe603;
}

#statcard {
  animation: fadeInAnimation ease 1s;
  animation-delay:0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

#statcard{
  margin:auto;
  width:105vh;
  height:70vh;
  border:transparent solid 1vh;
  z-index:2;
}

#statflip {
  border:transparent solid 10px;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  margin:auto;
  min-width:105vh;
  min-height:70vh;
  top:-10px;
  left:-10px;
}

.flipped #statflip {
  transform: rotateY(-180deg);
}

.flipped #statfront {
  animation: hide 0.8s;
  animation-fill-mode:forwards;
}

.flipped #flipButtonFront{
  visibility:hidden;
  display:none;
}

#statfront, #statback{
  position:absolute;
  display:flex;
  min-width:105vh;
  min-height:70vh;
  width:100%;
  height:100%;
  top:-1vh;
  left:-1vh;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  background: black;
}


#statfront, #statback{
  border: #bee1de solid 1vh;
  background-size:cover;
  display:flex;
  flex-direction:column;
}

#statcard{
  font-family: safety;
}

#statheader, #invheader{
  font-family: 'Courier New', Courier, monospace;
  color:#bee1de;
  display:flex;
  align-items:center;
  text-align:center;
  vertical-align: middle;
  margin:1%;
  border-width: 0 0 1vh 0;
  border-style: solid;
  border-color: #bee1de;
  margin-bottom:2vh;
  font-size:3.8vh;
}

#statheader > h1{
  text-align:center;
  display:inline;
  font-size:5vh;
}

#statback{
  transform: rotateY(-180deg);
  background-size:cover;
}

#stattabs{
  display:flex;
  flex-direction:column;
  flex: 1 1 auto;
  justify-content:center;
  align-items:center;
  padding:0 2vh 2vh 2vh;
}

#stattabbar{
  display:flex;
  width:100%;
  align-items:stretch;
  justify-content:space-around;
}

.tabButton{
  color:#bee1de;
  font-size:3vh;
  font-family: 'Courier New', Courier, monospace;
  font-weight:bold;
  background:none;
  border-width: 0.5vh 0.5vh 0 0.5vh;
  border-color: #bee1de;
  border-style: solid;
}

.tabButton:hover, .tabButton.active{
  background:#bee1de;
  color:black;
  font-size:3vh;
  font-family: 'Courier New', Courier, monospace;
  font-weight:bold;
  border-width: 0.5vh 0.5vh 0 0.5vh;
  border-color: #bee1de;
  border-style: solid;
}

#statfield{
  flex: 1 0 auto;
  height:10vh;
  width:100%;
  border-width: 0.5vh;
  border-color: #bee1de;
  border-style: solid;
  font-family:russoOne;
}

.achievementList{
  margin:3vh;
  color:#bee1de;
  overflow-y:scroll;
  height:calc(100% - 6vh);
}

.achievementList::-webkit-scrollbar { width: 0 !important }

.achievement{
  display:flex;
  flex-direction:row;
  text-align:left;
  position:relative;
  line-height:4vh;
  align-items:center;
}

.achievement > *{
  margin-left:1vh;
}

.achievement > img{
  height:3vh;
}

.achievement > p {
  font-size:3vh;
}

/* Inventory card */

#itemarrays{
  display:flex;
  flex: 1 1 auto;
  margin:1vh;
  margin-top:0;
  justify-content:space-between;
  height: 83%;
}

#itemarrays > * > h2{
  font-family:'Courier New', Courier, monospace;
  color:#bee1de;
  border-width: 0 0 0.5vh 0;
  border-style: solid;
  border-color: #bee1de;
  font-size:4vh;
}

#itemSpacer{
  flex-basis:0.5%;
  background:#bee1de;
}

#gachaWrapper, #collectableWrapper{
  flex-basis: 49.5%;
  margin-right:2vh;
  margin-left:1vh;
}

#collectableWrapper{
  margin-right:1vh;
  margin-left:2vh;
}

#gachaGrid{
  margin:auto;
  display:grid;
  width:100%;
  height:87%;
  grid-template-areas:'	g00	g01	g02	g03	g04	g05	g06	g07	g08	g09	'
  '	g10	g11	g12	g13	g14	g15	g16	g17	g18	g19	'
  '	g20	g21	g22	g23	g24	g25	g26	g27	g28	g29	'
  '	g30	g31	g32	g33	g34	g35	g36	g37	g38	g39	'
  '	g40	g41	g42	g43	g44	g45	g46	g47	g48	g49	'
  '	g50	g51	g52	g53	g54	g55	g56	g57	g58	g59	'
  '	g60	g61	g62	g63	g64	g65	g66	g67	g68	g69	'
  '	g70	g71	g72	g73	g74	g75	g76	g77	g78	g79	'
  '	g80	g81	g82	g83	g84	g85	g86	g87	g88	g89	'
  '	g90	g91	g92	g93	g94	g95	g96	g97	g98	g99	';
  grid-auto-rows: minmax(0, 1fr);
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: 1%;
  padding:0;
  margin-top:3%;
  margin-bottom:3%;
  width: 100%;
}

.gachaItem, .collectableItem{
  align-items:center;
  justify-content:center;
  display:flex;
  margin:0;
  font-family:'Courier New', Courier, monospace;
  background:gray;
  font-weight:bold;
}

.gachaItem{
  font-size:2.5vh;
}

.collectableItem{
  font-size:6vh;
}

.gachaItem > *, .collectableItem > *{
  margin:0;
  display:inline;
}


#collectableGrid{
  margin:auto;
  display:grid;
  width:100%;
  height:87%;
  grid-template-areas:
  '	c00 c01 c02 c03 c04 '
  '	c05 c06 c07	c08 c09 '
  '	c10 c11	c12 c13 c14'
  '	c15	c16 c17 c18 c19'
  '	c20	c21 c22 c23 c24';
  grid-auto-rows: minmax(0, 1fr);
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: 1%;
  padding:0;
  margin-top:3%;
  margin-bottom:3%;
  width: 100%;
}

.found{
  border: 7px #bee1de solid;
  color:black;
}

.gachaItem.found{
  background: #bee1de;
}

/*.collectableItem.found{
}*/

.collectableItem.possessed{
  background:#bee1de;
  color:black;
}

